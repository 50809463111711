import React from 'react';

const NotFound = () => {
    return (
        <div className='page-404'>
            <p>Page non trouvée !!</p>
        </div>
    );
}

export default NotFound;
