import React from 'react';

const MenuSocials = () => {
    return (
        <div className="side-menu">
            <ul className="socials-links">
                <li className="socials-links__item">
                    <a href="https://github.com/JoAnisky/" aria-label="Github" target="_blank" rel='noreferrer' >
                        <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 24 24" fill="none" strokeWidth="1.5" stroke="currentColor" role="img">
                            <title>Github</title>
                            <path fillRule="evenodd" clipRule="evenodd" d="M21 11.2535C21 15.7811 18.1374 19.6215 14.1665 20.9811C13.6585 21.0815 13.4792 20.762 13.4792 20.4877C13.4792 20.1514 13.4917 19.0449 13.4917 17.6747C13.4917 16.7172 13.1709 16.0919 12.8124 15.777C15.0395 15.5215 17.3792 14.6557 17.3792 10.7174C17.3792 9.59821 16.9918 8.68089 16.3499 7.96424C16.4541 7.7046 16.7957 6.66402 16.25 5.25119C16.25 5.25119 15.4124 4.97681 13.5041 6.30242C12.7062 6.07473 11.8501 5.9617 11 5.95761C10.1499 5.9617 9.29384 6.07473 8.49589 6.30242C6.58763 4.97681 5.74598 5.25119 5.74598 5.25119C5.20225 6.66402 5.54589 7.7046 5.65015 7.96424C5.00818 8.68089 4.61883 9.59821 4.61883 10.7174C4.61883 14.6451 6.95413 15.526 9.17515 15.7831C8.88965 16.0407 8.63142 16.492 8.5396 17.1534C7.9686 17.4175 6.52067 17.8704 5.6293 16.3024C5.6293 16.3024 5.1 15.3196 4.09796 15.2471C4.09796 15.2471 3.12077 15.2344 4.02899 15.8704C4.02899 15.8704 4.68539 16.1853 5.1397 17.3684C5.1397 17.3684 5.72513 19.3644 8.50832 18.7431C8.51233 19.6002 8.52075 20.2452 8.52075 20.4877C8.52075 20.76 8.33951 21.0749 7.83949 20.9811C3.86459 19.626 1 15.7831 1 11.2535C1 5.58945 5.47692 1 11 1C16.5231 1 21 5.58945 21 11.2535Z" />
                        </svg>
                    </a>
                </li>
                <li className="socials-links__item">
                    <a href="https://www.linkedin.com/in/jonathanlore42" aria-label="Linkedin" target="_blank" rel='noreferrer'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 13 12" fill="none" stroke="currentColor" role="img">
                            <title>Linkedin</title>
                            <path d="M12.3816 6.9349V11.75H10.2862V7.80049C10.2862 7.19777 10.1667 6.68521 9.88398 6.31863C9.59236 5.94048 9.15701 5.75566 8.61637 5.75566C8.03845 5.75566 7.56565 5.94327 7.24162 6.31746C6.92253 6.68595 6.77755 7.20112 6.77755 7.80049V11.75H4.77632V4.25H6.77755V5.0776V6.02266L7.2444 5.20201L7.24448 5.20187L7.24449 5.20184L7.24464 5.20159L7.24473 5.20142L7.24502 5.20095L7.24948 5.19355C7.25391 5.18634 7.26122 5.17471 7.27149 5.15929C7.29203 5.12842 7.32429 5.0825 7.36875 5.02651C7.45784 4.91434 7.59486 4.76306 7.78353 4.61149C8.15891 4.30991 8.73995 4.00559 9.56661 4.00559C10.4125 4.00559 11.1098 4.25034 11.5948 4.72099C12.0782 5.1901 12.3816 5.91378 12.3816 6.9349ZM1.54235 2.70246C0.818038 2.70246 0.25 2.1432 0.25 1.47623C0.25 0.809263 0.818038 0.25 1.54235 0.25C2.26658 0.25 2.83429 0.809178 2.83429 1.47623C2.83429 2.14329 2.26658 2.70246 1.54235 2.70246ZM2.60896 4.25V11.75H0.500822V4.25H2.60896Z"/>
                        </svg>
                    </a>
                </li>
                <li className="socials-links__item">
                    <a href="https://twitter.com/jonathanlore42" aria-label="Twitter" target="_blank" rel='noreferrer'>
                        <svg xmlns="http://www.w3.org/2000/svg"  width="24" height="24" viewBox="0 0 14 12" fill="none" stroke="currentColor" role="img">
                            <title>Twitter</title>
                            <path d="M12.6444 2.69268L12.5325 2.77404L12.542 2.91208C12.55 3.02824 12.5501 3.1458 12.5501 3.27457C12.5501 6.99374 9.77272 11.2547 4.70389 11.376L4.48111 11.3763C3.27985 11.3781 2.098 11.109 1.02074 10.5941C2.27798 10.528 3.48741 10.0737 4.48172 9.28946L5.03064 8.85653L4.33166 8.84321C3.32403 8.82401 2.425 8.24034 1.98552 7.353C2.32036 7.3702 2.65664 7.33389 2.98163 7.24472L2.96519 6.75863C1.8398 6.53029 0.998475 5.61143 0.845288 4.49379C1.17779 4.62297 1.53085 4.69509 1.88965 4.70585L2.7568 4.73184L2.03647 4.24838C0.963492 3.52824 0.56775 2.156 1.04779 0.987808C2.56716 2.70148 4.71173 3.73761 7.00522 3.85274L7.33587 3.86934L7.26134 3.54677C7.04563 2.61321 7.34042 1.63533 8.03516 0.979639L8.03518 0.979617C9.11204 -0.0369662 10.8053 0.0151335 11.8175 1.09604L11.9124 1.19735L12.0486 1.17039C12.4863 1.0837 12.9124 0.949182 13.3196 0.769895C13.0978 1.11527 12.7987 1.40783 12.4426 1.62194L12.6008 2.08446C12.9135 2.04744 13.2225 1.98608 13.5249 1.90112C13.26 2.19445 12.9649 2.45975 12.6444 2.69268Z" />
                        </svg>
                    </a>
                </li>
                <li className="socials-links__item">
                    <a href="https://www.instagram.com/jonathanlore42/" aria-label="Instagram" target="_blank" rel='noreferrer'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 14 16" fill="none" stroke="currentColor" role="img">
                        <title>Instagram</title>
                        <path d="M4.43182 0C1.98835 0 0 1.98835 0 4.43182V10.5682C0 13.0117 1.98835 15 4.43182 15H10.5682C13.0117 15 15 13.0117 15 10.5682V4.43182C15 1.98835 13.0117 0 10.5682 0H4.43182ZM4.43182 0.681818H10.5682C12.6431 0.681818 14.3182 2.35688 14.3182 4.43182V10.5682C14.3182 12.6431 12.6431 14.3182 10.5682 14.3182H4.43182C2.35688 14.3182 0.681818 12.6431 0.681818 10.5682V4.43182C0.681818 2.35688 2.35688 0.681818 4.43182 0.681818ZM11.5909 2.72727C11.2144 2.72727 10.9091 3.03253 10.9091 3.40909C10.9091 3.78565 11.2144 4.09091 11.5909 4.09091C11.9675 4.09091 12.2727 3.78565 12.2727 3.40909C12.2727 3.03253 11.9675 2.72727 11.5909 2.72727ZM7.5 3.75C5.43297 3.75 3.75 5.43297 3.75 7.5C3.75 9.56703 5.43297 11.25 7.5 11.25C9.56703 11.25 11.25 9.56703 11.25 7.5C11.25 5.43297 9.56703 3.75 7.5 3.75ZM7.5 4.43182C9.19855 4.43182 10.5682 5.80145 10.5682 7.5C10.5682 9.19855 9.19855 10.5682 7.5 10.5682C5.80145 10.5682 4.43182 9.19855 4.43182 7.5C4.43182 5.80145 5.80145 4.43182 7.5 4.43182Z"/>
                    </svg>
                    </a>
                </li>
            </ul>
        </div>
    );
}

export default MenuSocials;
